 .item-bold {
     font-weight: 600;
 }

 .csv-item-data {
     margin-top: 8px !important;
     margin-bottom: 8px !important;

 }

 .csv-item-header {

     border-bottom: 1px solid #dee2e6;
     padding-top: 10px;
     padding-bottom: 10px;
     margin-bottom: 16px !important;
     margin-top: 0px !important;

     .csv-item-header-logo {
         width: 52px !important;
         border-left: 1rem;
         padding-left: 0px;
     }

     .csv-item-header-data {}
 }

 .csv-item-header-fixed-image {
     height: 53px;
     padding-left: 5px;
 }

 .grid-row-mobile-odd {
     .csv-item-header {
         background: linear-gradient(to right, #efefef, #fff) !important;
     }
 }

 .grid-row-mobile-even {
     .csv-item-header {
         background: linear-gradient(to right, #fff, #e0e0e0) !important;
     }
 }



 .money-span {
     width: 98px !important;
     text-align: right;
     margin-right: 12px !important;
     padding-right: 5px;
 }


 .summary-row {
     font-weight: 600;
 }

 .spacer {
     font-weight: 600;
     margin-bottom: 10px;
 }

 .logos-container {
     position: relative;
     line-height: 55px;
     height: 55px;
     padding-left: 0px !important;
     margin-left: 6px;
 }

 .logo-container-w4 {
     width: 125px;
 }

 .logo-container-w3 {
     width: 78px;
 }

 .logo-container-w2 {
     width: 62px;
 }

 .logo-container-w1 {
     width: 45px;
 }

 .sett-status-icon {
     font-size: 18px;
 }

 .app-logo-img {
     height: 55px;
     width: 55px;
 }

 .app-logo-img-details {
     max-height: 55px;
 }


 .img_overlap_container0 {
     width: 60 px;
 }

 .img_overlap3 {
     position: absolute;
     z-index: 2;
     left: 120px;
 }

 .img_overlap0 {
     z-index: 6;
     left: 0;
     position: relative;
     top: -2px;
 }


 .img_overlap1 {
     position: absolute;
     z-index: 5;
     left: 23px;
     top: 0px;
 }

 .img_overlap2 {
     position: absolute;
     z-index: 4;
     left: 44px;
     top: 0px;
 }

 .img_overlap3 {
     position: absolute;
     z-index: 3;
     left: 90px;
     top: 0px;
 }


 .sett-cost-data {
     align-items: center;
 }

 .sett-rounded-icon {
     height: 37px !important;
     width: 37px !important;
 }

 .sett-rounded-icon-wrapper {
     height: 45px !important;
     width: 45px !important;
     padding-top: 5px;
 }


 .grid-row-mobile-odd {
     .sett-rounded-icon-wrapper {
         background-color: white;

     }
 }

 .grid-row-mobile-even {
     .sett-rounded-icon-wrapper {
         background-color: #e0e0e0 !important;
     }

 }

 .apps-chart-row {
    margin: 0px;
    padding: 0px;
     .apps-chart-row-apps {
        padding-left: 15px;
         img {
            
            background-color: white;
            border-radius: 50%;
             height: 95px;
             margin-left: -25px;
         }
     }
     .apps-chart-row-chart{
     }
 }



 @media (min-width: 1201px) {


     .apps-chart-row {
         display: flex;
         flex-direction: row;
         justify-content: space-between;
         height: 100px;
         .apps-chart-row-chart {
             width: 260px !important;
             height: 160px !important;
             margin-top: -50px;
             display: flex;
             justify-content: flex-end;
         }
     }
 }


 @media (max-width: 1200px) {

     .apps-chart-row {        
         display: flex;
         flex-direction: column;
         justify-content: center;         
         align-items: center;

         .apps-chart-row-apps {
            margin-left: 20px;
             img {
                background-color: white;
                border-radius: 50%;
                 height: 85px;
                 margin-left: -30px;
             }
         }

         .apps-chart-row-chart {
             width: 340px !important;
             height: 240px !important;
             margin-top: 0px !important;
             display: flex;
             justify-content: center;
         }
     }
 }