* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

body {
	background-color: #efefef !important;
	/* #F8F8FB;*/
	height: 100%;
	font-family: DM Sans;

}

.desktop-hide {
	display: none;
}

.main-title h3 {
	color: #163340;
	font-family: DM Sans;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.img-100 {
	position: relative;
}

.img-100 img.login-image {
	width: 100%;
	height: 100%;
	border-radius: 20px 0px 0px 20px;
	object-fit: cover;
}

.img-100 img.login-logo {
	position: absolute;
	top: 14px;
	left: 14px;
	max-height: 213px;
	background-color: #fff;
	border-radius: 20px;
}

.form-title h2, .form-title h6 {
	text-align: center;
	font-family: DM Sans;
}

.form-title h2 {
	font-size: 35px;
	font-style: normal;
	font-weight: 700;
	line-height: 45px;
	letter-spacing: -0.92px;
}

.blue {
	color: #009FE3 !important;
}

.light-text {
	color: #626262 !important;
}

.pdf-icon-color {
	color: #cd0e0e !important;
}

.outilne-icon-light-text {
	-webkit-text-stroke: 1px #163340;
	-webkit-text-fill-color: #ffffff;
}

.submit-btn {
	border-radius: 7px;
	background: #009FE3;
	box-shadow: 0px 1.1546331644058228px 2.3092663288116455px 0px rgba(0, 0, 0, 0.05);
	color: #FFF;
	font-family: DM Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 27.711px;
	padding: 12px;
	width: 100%;
	display: inline-block;
	border: none;
	text-align: center;
	text-decoration: none;
}

.divider p {
	padding: 0px 10px;
	margin: 0px;
	position: relative;
	z-index: 2;
	display: inline-block;
	position: absolute;
	top: -11px;
	left: 50%;
	transform: translateX(-50%);
	background: #fff;
	font-size: 16px;
	font-family: 'DM Sans';
}

.divider hr {
	position: relative;
	z-index: 1;
}

.divider {
	margin: 25px 0px;
	text-align: center;
	position: relative;
}

.signin-btn a {
	border-radius: 8px;
	border: 1px solid rgba(0, 159, 227, 0.50);
	box-shadow: 0px 1.1546331644058228px 2.3092663288116455px 0px rgba(0, 0, 0, 0.05);
	width: 100%;
	padding: 7px 16px;
	color: #163340;
	font-family: DM Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	display: inline-block;
	line-height: 27.711px;
	text-align: center;
	text-decoration: none;
}

.form-sec {
	background: #efefef;
	padding: 8px 8px;
}

.languages-div-horizontal, .languages-div-horizontal-mobile {
	display: flex;
	align-items: center;
	gap: 20px;
	justify-content: center;
}

.languages-div-horizontal>img {
	width: 38px !important;
	height: 38px !important;
}
.languages-div-horizontal-mobile{
	padding-top: 10px;
	padding-bottom: 0px;
}

.languages-div-horizontal-mobile>img {
	width: 35px !important;
	height: 35px !important;
}
 
.languages-div img {
	border-radius: 50%;
}

.languages-div-horizontal img.active, .languages-div-horizontal-mobile img.active {
	outline: thick solid #efefef !important;
}

.languages-div img.active {
	border-radius: 50%;
}

.btn-links-left a, .btn-links a {
	color: #6F7C8A !important;
	font-family: DM Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 27.711px;
	letter-spacing: 0.173px;
	text-decoration: none;
}

.right {
	text-align: right;
}

.bottom-sec {
	background: #efefef;
	padding-bottom: 5px;
	margin-top: 5px;
}

.bottom-sec-hidden{
	color: #efefef;
    font-size: 2px;
    justify-content: center;
    line-height: 0px;
}

.form-sec .with-bg {
	background: #fff;
	border-radius: 20px;
	padding-top: 5px;
}

.form-sec .col-md-6 {
	padding: 0px;
}

.items-center {
	align-items: center;
}


.sidemenu-styles {
	padding: 0px;
	margin: 0px;
	list-style: none;
}

aside::-webkit-scrollbar {
	width: 5px;
}

aside::-webkit-scrollbar-track {
	background: #f1f1f1;
}

aside::-webkit-scrollbar-thumb {
	background: #009fe3;
	border-radius: 10px;
}

aside {
	background: #163340;
	height: 100vh;
	position: sticky;
	top: 0;
	overflow-y: visible;
	overflow-x: hidden;
	z-index: 1;
}

.sidemenu-styles li span {
	color: rgba(255, 255, 255, 0.60) !important;
	font-family: DM Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 27.544px;
	text-decoration: none;
}

.sidemenu-styles li span>span {
	vertical-align: middle;
}

.sidemenu-styles li span img {
	margin-right: 12px;
	width: 24px;
	height: 24px;
}

ul.sidemenu-styles li {
	padding: 12px 14px;
	margin-bottom: 8px;
	transition: 0.5s all;
	border-radius: 8px;
}

.logo {
	padding-left: 5px;
	padding-right: 5px;
	line-height: 60px;
	padding-top: 5px;
	padding-bottom: 15px;
	text-align: left;
}

.logo img {
	/*padding: 0px 10px 0 0 ;*/
	/*width: 100%;*/
	max-height: 60px;
	object-fit: contain;
}

.p-none {
	padding: 0 !important;
}

.m-none {
	margin: 0 !important;
}

ul.sidemenu-styles li:hover,
ul.sidemenu-styles li.active {
	background-color: #009FE3;
}

.sidemenu-styles li:hover span,
.sidemenu-styles li.active span {
	color: #fff !important;
}

ul.sidemenu-styles li:hover img.icon,
ul.sidemenu-styles li.active img.icon {
	filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(14deg) brightness(100%) contrast(103%) !important;

}

ul.sidemenu-styles li:hover .app-icon {
	border-radius: 50%;
}

ul.sidemenu-styles li:hover .app-icon,
ul.sidemenu-styles li.active .app-icon {
	outline-color: #fff !important;
	background-color: #163340;
}




.btm-btn a {
	border-radius: 12px;
	border: 1px solid #34C759;
	width: 100%;
	padding: 14px 6px 14px 12px;
	color: #FFF;
	text-decoration: none !important;
	font-family: DM Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	background-color: #34C759;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	column-gap: 10px;
}

.side-btm {
	border-radius: 12px;
	background: #264958;
	margin: 20px 0px;
}

.btm-title h3 {
	color: #FFF;
	font-family: DM Sans;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-bottom: 10px;
}

.side-btm {
	padding: 10px;
	position: relative;
}

.side-btm img {
	position: absolute;
	top: 10px;
	right: -30px;
	height: 60px;
}

.form {
	position: relative;
}

.form i {
	position: absolute;
	top: 15px;
	left: 20px;
	color: #9ca3af;
}

.form span {
	position: absolute;
	right: 17px;
	top: 13px;
	padding: 2px;
	border-left: 1px solid #d1d5db;
}

.flag {
	width: 32px;
	height: 32px;
}

.form-input {
	height: 44px;
	text-indent: 33px;
	border-radius: 10px;
}

.form-input:focus {
	box-shadow: none;
}

.name-div h3 {
	color: #434A5E;
	font-family: DM Sans;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	margin: 0 !important;
}

.name-div {
	display: flex;
	align-items: center;
	gap: 10px;
}

.logout-div {
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 5px;
}

.logout-div>span {
	width: 60px;
}

.name-flag {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 20px;
}

.warning-txt {
	display: flex;
	align-items: center;
	gap: 14px;
}

.warning-txt h4 {
	color: #163340;
	font-family: DM Sans;
	font-size: 20px;
	font-weight: 700;
	line-height: normal;
}

.warning-txt p {
	color: #163340;
	font-family: DM Sans;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	margin: 0;
}

.warning-btn a {
	color: #163340;
	font-family: DM Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 21px;
	text-decoration: none;
}

.warning-btn {
	text-align: right;
}

.warning .row {
	padding: 10px 6px;
}

.warning-orange .row {
	border: 2px solid #FCA004;
	border-radius: 8px;
	background: #FFFBED;
}

.warning-orange {
	color: #FCA004;
}


.warning-red .row {
	border: 2px solid #E72749;
	border-radius: 8px;
	background: #FFFBED;
}

.warning-red {
	color: #E72749;
}



.warning {
	margin: 10px 12px 10px 12px;
}

.warning-thin {
	margin: -10px 12px 10px 12px !important;
}


.main-h2 h2 {
	color: #163340;
	font-family: DM Sans;
	font-size: 32px;
	font-style: normal;
	font-weight: 700;
	line-height: 40px;
	margin-bottom: 0px;
	display: flex;
	justify-content: space-between;


}


.const {
	border-radius: 8px;
	background: #009fe354;
	align-items: center;
	gap: 5px;
	padding: 5px 10px;
}

.const p {
	color: #009FE3;
	font-family: DM Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	margin: 0;
}

.profit {
	border-radius: 8px;
	background: rgba(52, 199, 89, 0.10);
	display: flex;
	align-items: center;
	gap: 5px;
	padding: 5px 10px;
}

.profit p {
	color: #34C759;
	font-family: DM Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	margin: 0;
}

.info-box span {
	color: #8E9ABB;
	font-family: DM Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	margin-bottom: 10px;
}

.info-box h3 {
	color: #163340;
	font-family: DM Sans;
	font-size: 32px;
	font-style: normal;
	font-weight: 400;
	line-height: 40px;
	margin: 0;
	margin-top: 10px;
}

.info-box {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	padding: 15px 0px;
	border-bottom: 1px solid #E2E9FC;
}

.bg-brdr {
	border-radius: 12px;
	background: #FFF;
	padding: 10px 15px 0px 15px;
}

.loss {
	border-radius: 8px;
	background: rgba(199, 52, 52, 0.10);
	display: flex;
	align-items: center;
	gap: 5px;
	padding: 5px 10px;
}

.loss p {
	color: #E72749;
	font-family: DM Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	margin: 0;
}

.info-box:last-child {
	border: none;
	padding-bottom: 10px;
}

.header {
	padding: 15px 15px 15px 0px;
	background: #fff;
	z-index: 999
}

.language-web {
	position: relative;
	display: flex;
	width: 250px;
	align-items: center;
	justify-content: flex-end;
}

.language-web>.flag {
	margin-left: 10px;
}

.user-mail {
	position: relative;
}

.pos-rel {
	position: relative;
}

.footer {
	position: absolute;
	bottom: 0;
	width: 100%;
}

.flex-div {
	display: flex;
	align-items: center;
	gap: 20px;
	margin-top: 10px;
}

.logos-chart {
	text-align: center;
}

.mt-40 {
	margin-top: 40px;
}

.info-det h3 {
	color: #163340;
	font-family: DM Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.id-date {
	display: flex;
	align-items: center;
	gap: 10px;
}

.flex-info {
	display: flex;
	align-items: center;
	gap: 5px;
}

.flex-info img {
	width: 15px;
	height: 15px;
	object-fit: cover;
}

.flex-info p {
	color: #8E9ABB;
	font-family: DM Sans;
	font-size: 15.404px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin: 0;
}

.f-part,
.l-part {
	display: flex;
	align-items: center;
	gap: 40px;
}

.profit-brdr.success-color span {
	color: #34C759 !important;
}

.profit-brdr.danger-color span {
	color: #E72749 !important;
}

.profit-brdr span {
	margin-right: 5px;
	font-family: DM Sans;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	border-radius: 8px;
	border: 1px dashed #E2E9FC;
	background: #fff;
	padding: 5px 15px;
	text-decoration: none;
}

.table-h-con h3 {
	color: #3A6E84;
	font-family: DM Sans;
	font-size: 15.202px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-bottom: 0;
}

.table-h-con span {
	color: #8E9ABB;
	font-family: DM Sans;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.table-h {
	display: flex;
	align-items: center;
	gap: 15px;
}

.table-h {
	padding-bottom: 20px;
	border-bottom: 1px solid #E2E9FC;
}

.table-b {
	padding-top: 20px;
}

.table-f-con span {
	color: #3A6E84;
	font-family: DM Sans;
	font-size: 15.202px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.table-f-con span.green {
	color: #35D187;
}

.table-f-con {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
}

.main-total {
	border-radius: 13px;
	border: 2px dashed #E6E8F1;
	padding: 10px;
}

.table-f-con:last-child {
	margin-bottom: 0px;
}

.pricing-table {
	padding: 30px 0px;
}

.pricing-table {
	padding: 30px 0px;
}

.table-f {
	margin-top: 20px;
}

.hidden-con {
	display: none;
	border-radius: 32px;
	background: #F8F8FB;
	padding: 32px;
}

.icon-rotate img {
	transform: rotate(180deg);
	transition: all 0.4s;
}

.settlement {
	padding: 80px 0px;
}

.header-mob {
	display: none;
	z-index: 999;
	border-bottom: 2px solid #efefef;
	padding-right: 15px;
	padding-left: 0px;
}

.content-b-p {
	margin-bottom: 0px;
	padding: 25px 130px 25px 130px;
}

.btn-links-left{
	display: flex;
	align-items: center;
	gap: 30px;
}

.btn-links-left a{
	display: flex;
	align-items: center;
}
.btn-links {
	display: flex;
	align-items: center;
	gap: 20px;
}

.btn-links.right {
	justify-content: end;
}

.only-mob {
	display: none;
}

#bar-chart-mobile {
	display: none !important;
}

.mobile-nav {
	display: none;
}

.nav-styles {
	margin-top: 13px;
}

.danger-btn {
	flex: 0%;
	text-align: center;
	border-left: 1px solid #E2E9FC;
}

.danger-btn a {
	border-radius: 8px;
	background: #E72749;
	padding: 12px 16px;
	color: #fff;
	text-decoration: none;
	display: inline-flex;
	align-items: center;
	column-gap: 10px;
}

.contract-type-btns {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 20px;
	padding-bottom: 15px;
	padding-top: 5px;
}

.contract-type-btns button:hover,
.contract-type-btns button:active,
.contract-type-btns a:hover,
.contract-type-btns a:active {
	background-color: #009FE3 !important;
	color: white !important;
}

.contract-type-btns button,
.contract-type-btns a {

	background: #F8F8FB;
	border: none;
	height: 120px;
	padding: 0px 4px 0px 10px;
	color: #163340;
	font-family: DM Sans;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 2px solid #009FE3;
	border-radius: 8px !important;
}



.contract-type-btns button.with-details,
.contract-type-btns a.with-details {
	padding-bottom: 36px;
}

.contract-type-btns-details {
	float: inline-end;
	margin-top: -36px;
	height: 36px;
	background-color: #009FE3;
	color: white;
	border-top-left-radius: 0 !important;
	border-top-right-radius: 0 !important;
	border-bottom-left-radius: 8px !important;
	border-bottom-right-radius: 8px !important;
	border: none;
	width: 100% !important;
	text-align: center;
	font-size: 13px;
	padding-top: 10px;

}



.stepform-checkbox {
	display: flex;
	align-items: center;
	column-gap: 12px;
}

input[type=text]:focus,
input[type=date]:focus {
	border: 1px solid #009FE3 !important;
	box-shadow: none;
}


.input-group>.form-control:not(:focus).is-invalid,
.input-group>.form-floating:not(:focus-within).is-invalid,
.input-group>.form-select:not(:focus).is-invalid,
.was-validated .input-group>.form-control:not(:focus):invalid,
.was-validated .input-group>.form-floating:not(:focus-within):invalid,
.was-validated .input-group>.form-select:not(:focus):invalid,
input {
	z-index: 0;
}




input[type=checkbox]:checked {
	background-color: #009FE3;
	border-color: #009FE3;
}

input[type=checkbox] {
	border-color: #009FE3;
	width: 20px;
	height: 20px;
	flex: none;
	min-height: 20px !important;
	vertical-align: middle;
}

.form-check {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-direction: row-reverse;
	gap: 12px;
}


.mb-3.checbox-div {
	margin-bottom: 30px !important;
}

.checbox-div {
	display: flex;
	align-items: center;
	column-gap: 10px;
}

.taxi-box {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.taxi-box .checbox-div {
	column-gap: 16px;
}

.checbox-div input {
	margin: 0px;
	padding: 0px;
}

.mb30 {
	margin-bottom: 30px !important;
}

.checbox-div label {
	margin-bottom: 0px !important;
}

input[type=checkbox]:focus {
	box-shadow: none;
}

.stepform-checkbox label {
	margin-bottom: 0px !important;
}

.card {
	z-index: 0;
	border: none;
	position: relative
}

.purple-text {
	color: #009fe3;
	font-weight: normal
}

.steps {
	font-size: 25px;
	color: gray;
	margin-bottom: 10px;
	font-weight: normal;
	text-align: right
}

.blue-btn {
	width: 100%;
	border-radius: 50px;
	background: #009FE3;
	color: #fff;
	border: none;
	padding: 12px 16px;
	margin-top: 20px;
	font-family: DM Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.thankyou-popup:hover {
	background: #009FE3;
}

.thankyou-popup {
	border-radius: 50px;
	background: #009FE3;
	border: none;
	padding: 12px 24px;
	color: #fff;
	float: right;
	margin-left: 15px;
}

.thankyou-popup:focus,
.thankyou-popup:active,
.thankyou-popup:focus-visible {
	background-color: #009FE3 !important;
	border-color: #009FE3 !important;
	box-shadow: none;
}

.thankyou-popup.previous {
	background: #E2E9FC;
	color: #009FE3;
}

.thank-btn {
	width: 100%;
}

.fieldlabels {
	color: gray;
	text-align: left;
}

.fit-image {
	width: 100%;
	object-fit: cover
}

/* Document Page CSS Start */
.list-document-head {
	display: flex;
	align-items: center;
	column-gap: 35px;
}

.list-document-head h2 {
	margin-bottom: 0px;
}

.list-document-head button {
	border-radius: 8px;
	background: #34C759;
	color: #fff;
	font-family: DM Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	border: none;
	padding: 12px 16px;
}

.list-document-head button:hover {
	background: #34C759;
}

.list-document {
	margin-top: 30px;
}

.list-document .documents {
	border-radius: 16px;
	background: #FFF;
	box-shadow: 0px 4px 25px 12px rgba(194, 194, 194, 0.18);
	padding: 30px;
	/* height: 100%; */
}

.list-document .documents .front {
	display: flex;
	align-items: center;
	justify-content: space-between;
	column-gap: 15px;
	height: 100%;
}

.list-document .documents .front h3 {
	color: #163340;
	font-family: DM Sans;
	font-size: 17px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-bottom: 12px;
	/* word-break: break-all; */
}

.list-document .documents .front p {
	color: #8E9ABB;
	font-family: DM Sans;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 14px;
	margin-bottom: 0px;
}

.list-document .documents .front .document-icon {
	/*	width: 40px;
	height: 40px;
	border: 1px solid #E2E9FC;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
*/
	cursor: pointer;
}

.list-document .documents .front .document-icon img {
	transition: all 0.4s;
}

.list-document .documents .front .document-icon.icon-rotate img {
	transform: rotate(180deg);
}

.list-document .documents .back p {
	margin-bottom: 0px;
	color: #8E9ABB;
	font-family: DM Sans;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.list-document .documents .back a {
	color: #009FE3;
	font-family: DM Sans;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	text-decoration: none;
}

.table-con h4 {
	font-size: 17px !important;
}

.table-con {
	font-size: 14px !important;
}

.list-document .documents .back h4 {
	color: #163340;
	font-family: DM Sans;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	margin-bottom: 0px;
}

.list-document .documents .back {
	border-radius: 16px;
	background: #F8F8FB;
	padding: 24px 16px;
	margin-top: 30px;
	display: none;
}

/*.list-document .documents .back span {
    border-radius: 50px;
    background: rgba(52, 199, 89, 0.15);
    color: rgba(52, 199, 89);
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    padding: 4px 12px;
}*/
.list-document .documents .back .table-f {
	margin: 0;
}

.list-document .documents .back .table-f .table-con {
	margin-bottom: 0;
}


.uploadFile {
	border-radius: 20px;
	border: 1px dashed #34C759;
	background: #FFF;
	padding: 32px;
	width: 100%;
}

.uploadFile .filename {
	color: #34C759;
	font-family: DM Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
}

.form-fields .uploadFile .deleteButton {
	color: #34C759;
}

.uploadFile [type="file"] {
	display: none;
}

.form-fields .input-field input:focus {
	outline: none;
}

.form-fields .input-field input {
	border-radius: 8px;
	border: 1px solid #E2E9FC;
	background: #fff;
	width: 100%;
	padding: 7px 12px;
}

.form-fields .input-field label {
	color: #163340;
	font-family: DM Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	margin-bottom: 6px;
}

.form-fields {
	margin-top: 32px;
}

.upload-field h4 {
	color: #163340;
	font-family: DM Sans;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-bottom: 19px;
}

.form-fields .input-field {
	margin-bottom: 20px;
}

.form-buttons .close {
	border-radius: 50px;
	background: #F8F8FB;
	border: none;
	color: #009FE3;
	font-family: DM Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	padding: 12px 16px;
}

.form-buttons .blue-btn {
	width: auto;
	margin: 0px;
}

.form-buttons {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 32px;
}

#poleCardModal .modal-body,
#residenceCardModal .modal-body,
#addInvoiceModal .modal-body {
	padding: 52px 40px;
}

.form-fields .flex-input {
	display: flex;
	align-items: center;
	column-gap: 24px;
}

.flex-chart {
	display: flex;
	justify-content: space-between;
	column-gap: 10px;
	height: 100%;
}

.flex-chart .main-h2 h2 {
	margin-bottom: 22px;
}

.con-box span {
	color: #8E9ABB;
	font-family: DM Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
}

.con-box h3>span,
.con-box h3 {
	color: #163340;
	font-family: DM Sans;
	font-size: 32px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}


.con-box h3>span.money-positive {
	color: #34C759 !important;
}

/* Settlement Page CSS End */

/* Invoices Page CSS Start */
.invoice-main-box {
	padding: 32px 40px;
}

.list-invoice-head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	column-gap: 35px;
}

.list-invoice-head h2 {
	margin-bottom: 0px;
}

.list-invoice-head button {
	border-radius: 8px;
	background: #009FE3;
	color: #fff;
	font-family: DM Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	border: none;
	padding: 12px 16px;
}

.list-invoice-head button:hover {
	background: #009FE3;
}

.invoice-table-nav button {
	color: #8E9ABB !important;
	font-family: DM Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	border: none !important;
	padding: 20px 0px;
	position: relative;
	display: flex;
	align-items: center;
	column-gap: 14px;
}

.invoice-table-nav button p {
	margin-bottom: 0px;
	width: max-content;
}

.invoice-table-nav button.blue.active:after {
	background: #009FE3;
}

.invoice-table-nav button.green.active:after {
	background: #34C759;
}

.invoice-table-nav button.purple.active:after {
	background: #8E9ABB;
}

.invoice-table-nav button.yellow.active:after {
	background: #FCA004;
}

.invoice-table-nav button.red.active:after {
	background: #E72749;
}

.invoice-table-nav button.active:after {
	content: '';
	border-radius: 50px;
	width: 100%;
	height: 4px;
	position: absolute;
	left: 0;
	bottom: 0px;
}

.invoice-table-nav button.active {
	color: #000 !important;
}

.invoice-table-nav button:hover {
	color: #000 !important;
}

.invoice-table-nav button:focus-visible {
	box-shadow: none;
}

.invoice-table-nav .nav-tabs::-webkit-scrollbar,
.table-responsive::-webkit-scrollbar {
	background-color: #F5F5F5;
	height: 6px;
}

.invoice-table-nav .nav-tabs::-webkit-scrollbar-track,
.table-responsive::-webkit-scrollbar-track {
	background-color: #F5F5F5;
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 20px;
}

.invoice-table-nav .nav-tabs::-webkit-scrollbar-thumb,
.table-responsive::-webkit-scrollbar-thumb {
	background-color: #555;
	border-radius: 20px;
}

.invoice-table-nav .nav-tabs {
	border-bottom: 1px solid #E2E9FC;
	column-gap: 50px;
}

.invoice-table-nav button.blue span {
	background: #009fe326;
	color: #009FE3;
}

.invoice-table-nav button.green span {
	background: rgba(52, 199, 89, 0.15);
	color: #34C759;
}

.invoice-table-nav button.purple span {
	background: #E2E9FC;
	color: #8E9ABB;
}

.invoice-table-nav button.yellow span {
	background: #FFFBED;
	color: #FCA004;
}

.invoice-table-nav button.red span {
	background: #F9EBEB;
	color: #E72749;
}

.invoice-table-nav button span {
	padding: 4px 12px;
	border-radius: 50px;
}

.invoice-table {
	margin-top: 32px;
}

.invoice-table * {
	border: none;
}

.invoice-table tr p {
	width: max-content;
	margin-bottom: 0px;
}

.invoice-table thead tr th {
	padding: 16px;
	background: rgba(226, 233, 252, 0.30);
	color: #8E9ABB;
	font-family: DM Sans;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 18px;
}

.invoice-table thead tr th:first-child {
	border-radius: 8px 0px 0px 8px;
}

.invoice-table thead tr th:last-child {
	border-radius: 0px 8px 8px 0px;
}

.invoice-table tbody tr td {
	color: #163340;
	font-family: DM Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 21px;
	padding: 16px;
}

.invoice-table tbody tr td .badge {
	border-radius: 50px;
	padding: 4px 12px;
	font-family: DM Sans;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 18px;
}

.invoice-table tbody tr td .green-badge {
	background: rgba(52, 199, 89, 0.15);
	color: #34C759;
}

.invoice-table tbody tr td .blue-badge {
	background: #009fe326;
	color: #009FE3;
}

.invoice-table tbody tr td .purple-badge {
	background: #E2E9FC;
	color: #8E9ABB;
}

.invoice-table tbody tr td .yellow-badge {
	background: #FFFBED;
	color: #FCA004;
}

.invoice-table tbody tr td .red-badge {
	background: #F9EBEB;
	color: #E72749;
}

.invoice-table tbody tr td .blue-link {
	color: #009FE3;
	font-family: DM Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 21px;
	display: flex;
	align-items: center;
	column-gap: 10px;
}

.invoice-table tbody tr td .blue-link i {
	font-size: 16px;
	font-weight: 500;
}

#addInvoiceModal .all-fields {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 32px 40px;
}

#addInvoiceModal .all-fields .full-width {
	grid-column: 1/3;
}

#addInvoiceModal .all-fields .input-field {
	margin-bottom: 0;
}

#addInvoiceModal .warning {
	padding: 0;
	margin: 25px 0px;
}

.blue-button {
	border-radius: 8px;
	border: 1px dashed #009FE3;
	background: #fff;
	padding: 12px 16px;
	color: #009FE3;
	font-family: DM Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin: 20px 0px;
	display: flex;
	align-items: center;
	column-gap: 10px;
}

.mb-20 {
	margin-bottom: 20px;
}

#thankmodal .modal-body {
	padding: 56px 40px;
	text-align: center;
}

#thankmodal .modal-body p {
	color: #8E9ABB;
	font-family: DM Sans;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin: 26px 0px;
}

/* Invoices Page CSS End */

/* Make Money Page CSS Start */
.working-list h2 {
	margin-bottom: 20px;
}

.working-list ul li {
	list-style: none;
	display: flex;
	align-items: center;
	column-gap: 20px;
	margin-bottom: 15px;
}

.working-list ul li:last-child {
	margin-bottom: 0px;
}

.working-list ul {
	margin: 25px 0 0 0;
	padding: 0;
}

.working-list ul li span {
	margin-left: 5px;
	width: 28px;
	height: 28px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #34C759;
	border-radius: 50%;
	color: #FFF;
	font-family: DM Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	flex: none;
}

.working-list ul li p {
	margin-bottom: 0px;
	color: #8E9ABB;
	font-family: DM Sans;
	font-size: 16px;
	font-weight: 500;
	line-height: 150%;
}

.working-list ul li p b {
	color: #163340;
}

.copy-link {
	display: flex;
	align-items: center;
}

.copy-link .copy-btn {
	border-radius: 12px 0px 0px 12px;
	border: 1px solid #F8F8FB;
	background: #fff;
	padding: 12px;
	display: flex;
	align-items: center;
	column-gap: 12px;
	color: #163340;
	font-family: DM Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}

.copy-link .copy-field {
	width: 100%;
}

.copy-link .copy-field input:focus {
	outline: none;
}

.copy-link .copy-field input {
	border-radius: 0px 12px 12px 0px;
	background: #F8F8FB;
	padding: 12px;
	width: 100%;
	border: none;
	color: #000;
	font-family: DM Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}

.working-list .input-fields label {
	color: #163340;
	font-family: DM Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	margin-bottom: 8px;
}

.working-list .input-fields input {
	border-radius: 8px;
	border: 1px solid #E2E9FC;
	width: 100%;
	padding: 8px 10px;
}

.working-list .input-fields {
	margin: 20px 0px;
}

.working-list .blue-btn {
	width: auto;
	border-radius: 8px;
	padding: 16px;
}

.working-list {
	position: relative;
	height: 100%;
}

.working-list .saving-img {
	position: absolute;
	top: -88px;
	right: -20px;
	width: 110px;
	height: 110px;
}

.aff-link-first-panel {
	height: 285px;
}

.aff-link-first-panel-shitf {
	margin-top: 25px;
}

.price {
	color: #009FE3;
	font-family: DM Sans;
	font-size: 32px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.mt-25 {
	margin-top: 25px;
}

/* Make Money Page CSS End */

/* Contact Page CSS Start */
.all-fields {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 32px;
}

.all-fields .input-fields label {
	color: #163340;
	font-family: DM Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	margin-bottom: 8px;
}

.all-fields .input-fields input:focus,
.all-fields .input-fields textarea:focus {
	outline: none;
}

.all-fields .input-fields input,
.all-fields .input-fields textarea {
	border-radius: 8px;
	border: 1px solid #E2E9FC;
	width: 100%;
	padding: 8px 12px;
}

.all-fields .input-fields.full-width {
	grid-column: 1/3;
}

.contact-form .blue-btn {
	border-radius: 8px;
	margin: 0;
}

.contact-form h2 {
	margin-bottom: 14px;
}

/* Contact Page CSS End */
.verified-txt {
	display: flex;
	align-items: center;
	gap: 15px;
}

.verified-txt h3 {
	color: #009FE3;
	font-family: DM Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	margin: 0;
}

.noti-h h3 {
	color: #163340;
	font-family: DM Sans;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin: 0;
}

.noti-dollar-con h3 {
	color: #163340;
	font-family: DM Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	margin: 0;
}

.noti-dollar-con span {
	color: #8E9ABB;
	font-family: DM Sans;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.noti-dollar-box {
	display: flex;
	gap: 15px;
}

.noti-file-box {
	display: flex;
	align-items: center;
	gap: 15px;
}

.noti-file-box h3 {
	color: #163340;
	font-family: DM Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	margin: 0;
}

.noti-f h3 {
	color: #009FE3;
	font-family: DM Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	margin: 0;
}

.warning .warning-btn a {
	background-color: #009fe3;
	padding: 12px 16px;
	color: #fff;
	border-radius: 8px;
	display: inline-block;
}

.cooperation .row {
	padding: 36px 38px;
}

.cooperation-box {
	cursor: pointer;
}

.forget-password {
	margin-top: 25px;
}

.forget-password p {
	color: #8E9ABB;
	font-family: DM Sans;
	font-size: 12px;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 0px;
}

.forget-password p a {
	color: #009FE3;
	text-decoration: none;
}

.contact-socialicon {
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 40px;
	margin-top: 40px;
}


@keyframes shake-animation {
	0% {
		transform: rotate(-30deg);
	}

	4% {
		transform: rotate(30deg);
	}

	8%,
	24% {
		transform: rotate(-20deg);
	}

	12%,
	28% {
		transform: rotate(20deg);
	}

	16% {
		transform: rotate(-37deg);
	}

	20% {
		transform: rotate(37deg);
	}

	32% {
		transform: rotate(-27deg);
	}

	36% {
		transform: rotate(27deg);
	}

	40%,
	100% {
		transform: rotate(0deg);
	}
}

.show-more {
	text-align: center;
}

.show-more .blue-btn {
	display: inline-block;
	border-radius: 8px;
	width: auto;
	text-decoration: none;
}

.error {
	color: #E72749;
}

.error-msg {
	color: #E72749;
	font-family: DM Sans;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 30px;
	display: none;
}

.head-with-badge {
	display: flex;
	align-items: center;
	gap: 7px;
	margin-bottom: 8px;
	flex-wrap: wrap;
	padding-top: 2px;
}

.head-with-badge h3 {
	margin-bottom: 0px !important;
}

.head-with-badge .badge {
	font-family: DM Sans;
	font-size: 11px;
	font-style: normal;
	font-weight: 500;
	line-height: 18px;
	border-radius: 50px;
	padding: 4px 12px;
}

.head-with-badge .green-badge {
	background: rgba(52, 199, 89, 0.15);
	color: #34C759;
}

.green-badge-color {
	color: #34C759;
}


.head-with-badge .red-badge {
	background: #F9EBEB;
	color: #E72749;
}

.head-with-badge .gray-badge {
	background: #ebebeb
		/*#181818*/
	;
	color: #4c4c4c
		/*#989797*/
	;
}

.orange-badge-color {
	color: #fc6604;
}

.head-with-badge .orange-badge {
	background: #fff1c1de;
	color: #fc6604;
}

.head-with-badge .blue-badge {
	background: #009fe326;
	color: #009FE3;
}

.mobile-logout a {
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 12px;
	color: #fff;
	font-family: DM Sans;
	font-size: 16px;
	font-weight: 500;
	line-height: normal;
	text-decoration: none;
}

.mobile-logout {
	padding-top: 15px;
	border-top: 1px solid rgba(255, 255, 255, 0.08);
	margin-top: 20px;
}


.number-list {
	justify-content: start;
	column-gap: 10px;
}

.number-list p {
	width: 25px;
	height: 25px;
	background-color: #009fe3;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff !important;
	font-size: 12px !important;
}

.number-list p img {
	width: 11px;
}

#signature {
	border-radius: 8px;
	border: 1px solid #E2E9FC;
	margin: 18px 0px;
	cursor: crosshair;
	width: 100%;
}

.red-badge span {
	background: #F9EBEB !important;
	color: #E72749 !important;
}

.blue-btn.close {
	position: unset;
	background: #009FE3;
	color: #fff;
	border: none;
	font-size: 16px;
	font-weight: 500;
}

.cooperation a {
	flex-wrap: wrap;
	justify-content: start;
}

.mobile-dropdown-box.language-click ul li a img {
	width: 25px;
}


.wrapper-flex .main-h2 {
	margin-bottom: 20px;
}

.wrapper-flex {
	display: flex;
	column-gap: 100px;
}

.wrapper-row {
	margin-top: 8px
}


.mobile-flag-row {
	margin-left: 18px;
	padding-left: 10px;
	padding-top: 20px;
	border-top: 1px solid rgba(255, 255, 255, 0.08);
	margin-top: 20px;
	display: flex;
	align-items: center;
	justify-content: left;
	gap: 19px;
}

.mobile-flag-row img {
	width: 36px;
	border: 3px solid #264958;
	border-radius: 50%;
}

.mobile-flag-row img.active {
	width: 36px;
	border: 3px solid #009FE3 !important;
	border-radius: 50%;
}


label.form-label,
label.control-label {
	margin-bottom: 0px;
	font-weight: 500;
	font-size: 14px;
}



input:disabled {
	color: #999999;
	background-color: #f2f2f2;
}



.inline-buttons {
	padding-top: 23px;
}

.btn-color-default {
	background-color: #009fe3 !important;
	color: white !important;
}

.btn-outline-default.btn-color-default {
	border: 1px solid #009fe3 !important;
	color: black !important;
	background-color: white !important;
}

.btn-outline-success.btn-color-success {
	border: 1px solid #0d9516 !important;
	color: black !important;
	background-color: white !important;
}




.btn-color-success-light {
	background-color: rgb(73, 230, 97) !important;
	color: white !important;
	border-color: red !important;
}


.btn-color-green {
	background-color: #34C759 !important;
	color: white !important;

	font-family: DM Sans;
}




.btn-color-danger {
	background-color: #E72749 !important;
	color: white !important;
}

.input-box button {
	border: none;
	padding: 8px 15px 7px 15px;
	color: #fff;
	border-radius: 8px;
	display: flex;
	align-items: center;
	column-gap: 5px;
	font-family: DM Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	width: auto;
}

.input-box button img {
	padding-left: 10px
}

.fade-in-out-animation {
	animation: fade-in-out-in 2s linear infinite
}

@keyframes fade-in-out-in {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0.1;
	}

	100% {
		opacity: 1;
	}
}


.head-with-badge>.badge {
	margin-top: 3px;
}

.icon-right {
	padding-left: 10px !important;
	padding-right: 0px !important;
	margin-right: -3px;
}

.icon-left {
	padding-left: 0px !important;
	padding-right: 10px !important;
	margin-left: -3px;
}


.logo-panel-mobile {
	display: flex !important;
	align-items: flex-start;
	gap: 10px;
	padding-right: 15px;
	justify-content: center;
	flex-direction: row;


	background-color: #efefef;
	margin-top: 5px;
	border-radius: 20px;
	padding-top: 5px;
}

.logo-panel-mobile2024 {
	margin-top: -5px;
    margin-left: -12px;
    margin-right: -12px;
}

.logo-mobile2024 {
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	width: 100%;
	max-height: 230px;	
}

.logo-mobile2024-img-container{
	position: relative;
	-webkit-mask-image: linear-gradient(to bottom, black 97%, transparent 100%);
	mask-image: linear-gradient(to bottom, black 97%, transparent 100%);
}

.logo-mobile2024-text{
	color: #004e93 !important;
	position: absolute;
	top: 13px;
	left: 13px;
	max-width: 80%;
}

.logo-mobile2024-text > h5{
	font-weight: bold;
}
.logo-mobile2024-text > h6{
	margin-bottom: 3px;
	line-height: 1rem;

}

.logo-mobile {
	margin-top: 0px;
	width: calc(100% - 60px);
	max-width: 250px;
	max-height: 230px;
}

.unauth-panel {
	padding: 12px 30px 12px 30px;
}


input,
button {
	line-height: 30px !important;
	min-height: 30px !important;
}

body,
.prevent-select {
	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-user-select: none;
	/* Safari */
	-khtml-user-select: none;
	/* Konqueror HTML */
	-moz-user-select: none;
	/* Old versions of Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	user-select: none;
	/* Non-prefixed version, currently
									supported by Chrome, Edge, Opera and Firefox */

}


.to-white {
	filter: brightness(0) invert(1);
}

.stepper-wizard {
	display: flex;
	gap: 10px;

}

.stepper-wizard>.active {
	background-color: #009FE3 !important;
}

.stepper-wizard>.inactive {
	background-color: #efefef !important;
}

.stepper-wizard>.step {
	height: 10px;
}


.cursor-pointer {
	cursor: pointer;
}


.money-positive {
	/*color: green !important;*/
}

.money-negative {
	color: #E72749 !important;
	font-weight: 400;
}

body::-webkit-scrollbar {
	width: 6px;
	background-color: #efefef;
}



body::-webkit-scrollbar-thumb {
	width: 6px;
	background-color: #fff;
}


.footer-info {
	color: #6F7C8A !important;
	font-family: DM Sans;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 27.711px;
	letter-spacing: 0.173px;
	text-decoration: none;
	text-align: center;
}

.row-item-header {
	font-family: DM Sans;
	font-weight: 600;
	font-size: 21px;
}


.no-data-info {
	color: #8E9ABB;
	font-family: DM Sans;
	font-style: normal;
	font-weight: 400;
	font-size: 14px !important;
}

.pdf-viewer-top-bar {
	padding-left: 10px;
	background-color: #eeeeee;
	color: #000;
	font-family: DM Sans;
	font-style: normal;
	font-weight: 500;
	font-size: 14px !important;
}

.pdf-viewer-top-bar-close {
	color: #60617e;
	margin-left: 5px;
	padding-right: 10px;
}

.suggested-app-nav-div {
	width: 42px !important;
	height: 42px !important;
	padding: 0px !important;
	outline: 2px solid #999999;
	border-radius: 50%;
	margin: 0 !important;
}

.suggested-app-nav-img {
	width: 42px !important;
	height: 42px !important;
	padding: 0px !important;
	margin: 0 !important;
}



.forced-icon-color {
	filter: invert(62%) sepia(47%) saturate(0%) hue-rotate(222deg) brightness(88%) contrast(100%) !important;
}

.forced-icon-color.actve {
	filter: invert(62%) sepia(47%) saturate(0%) hue-rotate(222deg) brightness(88%) contrast(100%) !important;
}


.ping {
	animation: ping 4s ease-in-out infinite both;
	/*tyle samo co timeout w MenuItemDynamicSuggestedApps*/
}

@keyframes ping {
	0% {
		opacity: 0.0;
	}

	5% {
		opacity: 0.5;
	}

	20% {
		opacity: 1.0;
	}

	80% {
		opacity: 1.0;
	}

	95% {
		opacity: 0.95;
	}

	100% {
		opacity: 0.0;
	}
}


.dashboard-waiting-settlements-chart {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.dashboard-waiting-settlements-chart-left {
	width: 55px;
	display: flex;
	flex-flow: column;
}


.dashboard-waiting-settlements-chart-right {
	width: 300px;
	height: 280px;
}

.href-app-store>img,
.href-app-store,
.href-app-store:hover,
.href-app-store:active,
.href-app-store:focus {
	text-decoration: none !important;
	background-color: #fff !important;
	border: none !important;
	color: white !important;
	box-shadow: none;
	text-shadow: none;
}

.href-app-store>img {
	object-fit: cover;
	width: 100%;
}

.swal2-html-container-div {
	overflow: hidden;
}

.btn-agr-type {
	display: flex;
	align-items: center;
	gap: 10px;
	justify-content: flex-start;
}

.privacy-policy {
	padding: 10px 20px;
}

.privacy-policy p {
	text-align: justify;
}

.privacy-policy h1,
.privacy-policy h2 {
	padding-top: 30px;
	padding-bottom: 30px;
}


.notification-badge {
	border-radius: 4px;
	background-color: #e72749;
	color: white;
	min-width: 26px;
	max-width: 38px;
	margin-left: 8px;
	text-align: center;
	font-size: 12px;
}

.bell-icon {
	cursor: pointer;
	font-size: 1.75em;
	color: #434A5E;
}

.bell-icon-container {
	cursor: pointer;
	position: relative;
	display: inline-block;
	margin-top: 2px;
}

.bell-icon-badge {
	cursor: pointer;
	position: absolute;
	top: -4px;
	left: 10px;
	text-align: center;
	color: white;
	width: 20px;
	height: 20px;
	border-radius: 6px;
	background-color: #e72749;
	z-index: 999;
	font-size: 12px;
}

.notification-image-container {
	margin-left: -3px;
}

.notification-image-container > h3 {
	margin-bottom: 0px;
}

.notification-image {
	width: 100%;
	max-height: 200px;
	object-fit: fill;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;

}

.notification-body {
	font-weight: 400;
	color: #8e9abb;
	font-family: DM Sans;
	font-size: 14px !important;

	background-color: white;
	margin-left: -10px;
    margin-right: -10px;
    margin-top: -10px;
    padding-left: 5px;
    padding-right: 5px;
	padding-bottom: 5px;

}

.notification-body img {
	max-width: 100%;
	margin: auto;
    display: block;
}


.formatted-text {
	/*white-space: pre-wrap; */
	text-wrap: pretty;
	word-break: break-word;
	
}

.notification-card  .accordion-panel {
	background-color: #fff !important;

}

.button-grid-to-left {
	margin-left: -8px;
}

.dashboard-card>.mgr-row {
	background-color: #FFF;
}


.carousel-container {
	width: 100%;
	border-radius: 12px;
	padding: 8px;
	background-color: #fff;
	text-align: center;
}

.carousel-item, .carousel-inner {
	border-radius: 8px;	
}

.carousel-image {
	max-height: 50vh;	
	z-index: 1;
    position: relative;
}
.carousel-image-blured{
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	filter: blur(50px);
	z-index: 0;
}

.carousel-image {
	border-radius: 6px;
	mask-image:   linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(21,216,255,1) 5%, rgba(255,255,255,1) 5%, rgba(21,216,255,1) 95%, rgba(0,212,255,0) 100%);
}

.carousel-caption {
	left: 8% !important;
	right: 8% !important;
	color: rgb(255, 255, 255);
	background-color: rgba(52, 54, 54, 0.712);
	display: block !important;
	padding: 2px 1px !important;
	z-index: 3;
}

.carousel-caption > h3 {
	font-size: 20px;
	margin-top: 8px;
}

.carousel-indicators{
	z-index: 4;
	margin-bottom: 13px;
}

.carousel-indicators>button {
	height: 18px !important;
	width: 18px !important;
	line-height: 18px !important;
	min-height: 18px !important;	
}

.notification-body p {
	margin-top: 0;
    margin-bottom: 0.25rem;
	font-family: "DM Sans" !important;
}

.ue-vat-error-info{
	font-size: 13px;
    color: #E72749;
    line-height: 13px;
    padding-bottom: 14px;
}


@media (min-width: 1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1480px;
    }
}