.image-preview {
    width: 100%;
    max-width: 400px;
}

.image-preview-no-limit {
    width: 100%;
}

.custom-drop-zone {
    width: 100%;
    background: white;
    border-radius: 12px;
    min-height: 80px;
    border: 2px dashed #009FE3 !important;
    font-family: DM Sans;
    border-image: none;
    margin-left: auto;
    margin-right: auto;
}

.db-image {
    position: relative;
}
.db-image-sink-min {
    width: calc(50% - 5px);

    img {
        height: 100%;
        object-fit: cover;
    }
}

.db-image-sink-max {
    width: 100%;
}

.db-image-preview-max {
    width: 100%;
    object-fit: contain;
}

.db-image-preview-upload {
    width: 100px;
    min-height: 100px;
    object-fit: contain;
}


.db-image-preview-min {
    width: 100%;

}

.images-preview-flex {

    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    row-gap: 6px;
}

.file-name {
    font-family: DM Sans;
    text-transform: lowercase;
    font-size: 14px;
}

.relative-top-right {
    position: absolute;
    top: 20px;
    right: 10px;
}

i.relative-top-right{
    -webkit-text-stroke: 1px #163340;
    -webkit-text-fill-color: #ffffff;
    z-index: 15;
}