@media (max-width: 1199px) {
 
	.main-logo.desktop-hide {
	    display: block;
	}
	 .mobile-hide{
		display: none;
	}
	 
	.thankyou-popup.close {
	    position: unset;
	    background: #009FE3 !important;
	    color: #fff !important;
	    border: none !important;
	    font-size: 16px !important;
	    font-weight: 500 !important;
	}

	.button-grid-to-left{
		margin-left: 0px;
	}
}