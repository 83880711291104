@media (min-width: 1200px) and (max-width: 1500px) {
	 
	aside {
	    padding: 0;
	}
	.sidemenu-styles{
		margin: 0px;
	}
	ul.sidemenu-styles li {
	    padding: 8px 0px 8px 10px;
	}
	.btm-title h3 {
	    font-size: 16px;
	}
	 
	 
	.side-btm {
	    padding: 15px 10px;
	}
	.header {
	    padding: 15px 15px 15px 0;
	}
 
	.content-b-p {
	    padding: 50px 50px;
	}
	.main-h2 h2 {
	    font-size: 26px;
	}
	.con-box h3 > span,
	.con-box h3 {
	    font-size: 24px;
	}
	.con-box span {
	    font-size: 14px;
	}
	.invoice-table-nav .nav-tabs {
	    flex-wrap: nowrap;
	    overflow: hidden;
	    overflow-x: scroll;
	}
	.all-fields {
	    gap: 14px;
	}
	 
	.list-document .documents .front h3 {
	    font-size: 17px;
	}
	.list-document .documents .front p {
	    font-size: 11px;
	}
	.head-with-badge .badge {
	    padding: 4px 10px;
	    font-size: 11px;
	}

	.wrapper-flex {
	    column-gap: 40px;
	}

	.unauth-panel{
		padding: 5px 15px;
	}
	
}
