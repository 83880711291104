@media (min-width: 992px) and (max-width: 1199px) {
	.row.items-center.h-100 {
	    height: auto !important;
	}
	aside {
		display: none;
	}
	.overlay {
		position: relative;
	}
	.col-md-1 , .col-md-2 , .col-md-3 , .col-md-4 , .col-md-5 , .col-md-6 , .col-md-7 , .col-md-8 , .col-md-9 , .col-md-10 , .col-md-11 , .col-md-12 {
		width: 100%;
	}
	 
	.overlay:after {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-color: #000;
		opacity: 0.38;
	}
	.menu-open {
		background: transparent;
		outline: none;
		border: none;
		box-shadow: none;
	}
	.header-mob{
		background-color: #fff;
	}
	.mobile-nav {
		display: block;
		position: fixed;
		top: 0px;
		right: 0px;
		width: 310px;
		height: 100vh;
		background: var(--dark);
		overflow-x: hidden;
		overflow-y: auto;
		z-index: 99999;
		pointer-events: none;
		transform: translateX(100%);
		opacity: 0;
		transition: 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
	}
	.close {
		position: absolute;
		top: 10px;
		right: 20px;
		font-size: 30px;
		font-weight: bold;
		color: white;
		cursor: pointer;
		background: none;
		border: none;
	}
	.header {
		display: none;
	}
	.header-mob {
		display: block !important;
	}
	.id-date {
		gap: 10px;
		flex-direction: column;
		align-items: flex-start;
	}
	.profit-brdr span {
		font-size: 15px;	
	}
	.info-det h3 {
		font-size: 13px;
	}
	.flex-info p {
		font-size: 12px;
	}
	.flex-info {
		gap: 5px;
	}
	.l-part {
		align-items: flex-end;
		flex-direction: column;
		gap: 10px;
	}
	.arrow img {
		width: 30px;
	}
	.f-img img {
		width: 90%;
	}
	.f-part {
		gap: 10px;
	}
	.main-h2 h2 {
		font-size: 24px;
		line-height: 33.499px;
	}
	.con-box span,
	.info-box span {
		font-size: 13.4px;
		line-height: 20.1px;
	}
	.con-box h3,
	.info-box h3 {
		font-size: 26.799px;
		line-height: 33px;
	}
	 
  
	.footer {
		background: #163340;
		padding: 15px;
	}
	 
	.logo img {
		width: 60%;
	}
	.m-header {
		display: flex;
		gap: 10px;
		align-items: center;
	}
	.mob-nav {
		display: flex;
		align-items: center;
		justify-content: right;
		gap: 50px;
	}
	.settlement {
		padding: 50px 0px;
		margin-bottom: 50px;
	}
	.mobile-nav.active {
		transform: translateX(0px);
		opacity: 1;
		pointer-events: unset;
		background: #163340;
	}
	.warning-btn {
		text-align: left;
		margin-top: 10px;
	}
	.warning-txt p {
		font-size: 12px;
	}
	.res-btm {
		margin-bottom: 10px;
	}
	.form-title h2 {
		font-size: 26px;
		line-height: 28.506px;
	}
	.img-100 img{
		border-radius: 25px 25px 0px 0px;
	}
 
	.only-mob {
		display: block !important;
	}
	.f-part {
		width: 75%;
	}
	section.header-mob {
		position: sticky;
		top: 0;
	}
	.overlay .header-mob {
		z-index: -1;
	}
	.overlay .main-content {
		position: relative;
		z-index: -2;
	}
	 
	.btn-links-left a, .btn-links a{
		font-size: 13px;
	}
	.btn-links-left {
		justify-content: flex-start !important;
	}
	.btn-links {
		justify-content: space-between !important;
	}
	.logo {
		padding: 20px 0px;
	}
	#bar-chart-desktop {
		display: none !important;
	}
	#bar-chart-mobile{
		display: block !important;
	}
 
	.content-b-p {
		padding: 50px 0px;
	}
	.mobile-dropdown-box{
		display: none;
	}
	.mobile-language {
		position: relative;
	}
	.mobile-user {
		position: relative;
	}
	.mobile-dropdown-box {
		position: absolute;
		right: 0;
	}
	.mobile-dropdown-box ul {
		padding: 15px;
		margin: 0;
		list-style: none;
		background-color: #fff;
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
	}
	.mobile-dropdown-box ul li a {
		display: flex;
		align-items: center;
		column-gap: 16px;
		color: #163340;
		font-family: DM Sans;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		text-decoration: none;
	}
	.user-language-active {
		display: block;
	}
	.side-btm {
		margin: 20px 20px;
	}
	.danger-btn {
		border-left: none;
		border-top: 1px solid #E2E9FC;
		padding-top: 50px;
	}
	 
	.contract-type-btns {
		grid-template-columns: repeat(1,1fr);
	}
 
	#thankyouModal .modal-body {
		padding: 25px;
	}
	.list-document {
		grid-template-columns: repeat(1,1fr);
	}
	.list-document-head {
		flex-wrap: wrap;
		gap: 18px;
	}
	.list-document-head button {
		font-size: 13px;
	}
	.list-document .documents {
	    padding: 20px 15px;
	    margin-bottom: 20px;
	}
	.form-fields .flex-input {
		flex-wrap: wrap;
	}
	.uploadFile {
		padding: 24px;
	}
	.uploadFile .filename {
		font-size: 14px;
	}
	.uploadFile img {
		width: 20px;
		height: 20px;
	}
	.form-buttons {
		flex-wrap: wrap;
		gap: 20px;
		justify-content: center;
	}
	.form-buttons .blue-btn , .form-buttons .close {
		font-size: 16px;
	}
	#poleCardModal .modal-body, #residenceCardModal .modal-body {
		padding: 40px 20px;
	}
	.flex-chart {
		margin-top: 40px;
		flex-wrap: wrap;
		justify-content: center;
	}
	.invoice-table-nav .nav-tabs {
		flex-wrap: nowrap;
		overflow: hidden;
		overflow-x: scroll;
	}
	.list-invoice-head {
		flex-wrap: wrap;
		gap: 20px;
	}
	
	.working-list{
		height: auto;
	}

	.working-list .saving-img {
	    position: absolute;
    	top: -80px;
    	right: -15px;
    	width: 110px;
    	height: 110px;
	}

 

 	.sidemenu-styles{
		margin: 0px 5px;
	}
	ul.sidemenu-styles li {
	    padding: 8px 10px;
	}


	.wrapper-flex {
		flex-wrap: wrap;
		gap: 50px;
	}

	.inline-buttons {
		padding-top: 5px !important;
		padding-bottom: 20px !important;		
	}

	.unauth-panel{
		padding: 20px;
	 }

	 .button-grid-to-left{
		margin-left: 0px;
	}
 
}
 
