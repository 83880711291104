.select-with-errors {
    border: solid 1px #f46a6a;
    border-radius: 8px
}

.option-boundary {
    border: 1px solid #efefef;
    max-width: 34px;
    max-height: 34px;
    margin-right: 8px;
}

.custom-select>div>div>div {
    line-height: 34px !important;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.custom-select>div>div>div,
.custom-select>div>div>div>input,
.custom-select>div>div>div>input:focus,
.custom-select>div>div>input :focus,
.custom-select>div>div>input {
    border: none !important;
    outline: none !important;
}

.custom-select>div {
    border-color: #dee2e6;
    border-radius: 6px !important;
}


.form-control {
    border-radius: 6px !important;
}


.btn {
    border-radius: 6px !important;
}


.input-rigth-button {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.input-left-button {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}
