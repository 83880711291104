.agr-FIN{
    color: darkgray
}
.agr-SIG{
   color: rgb(28, 127, 61)
}

.agr-REJ{
    color: red;
} 

.agr-W4C {
    color: rgb(145, 63, 246)
}