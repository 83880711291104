.inv-ARC {
    color: darkgray
}

.inv-W4C, .inv-W4S  {
    color: blue
}

.inv-AS, .inv-AC {
    color: rgb(28, 127, 61)
}

.inv-ASP {
    color: orange;
}

.inv-CNC {
    color: red;
}
 
.items-header{
font-weight: 600;
margin-bottom: 5px;
}

.inv-files-header{
    margin-top: 10px !important;
}