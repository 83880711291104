.grid-row-mobile {
    text-align: left;
    vertical-align: middle;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 8px;
    border-radius: 1rem;

    .accordion-body {
        padding-top: 0px;
        padding-left: 0px;
        color: var(--bs-body-color) !important;
    }

    .accordion-button {
        padding-top: 5px;
        padding-bottom: 15px;
        color: var(--bs-body-color) !important;
    }
}

.mgr-row-header-top {
    margin-left: 0px !important;
    margin-right: 0px !important;
    //padding-top: 5px;
    padding-left: 5px;
    //height: 65px;
    align-items: center;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;

}

.mgr-row-header-bottom {
    font-size: 11px;
    padding-top: 5px;
    margin-top: 8px;    
    text-align: right;
    color: #8E9ABB;
    font-family: DM Sans;
}

.mgr-row-exclaimed{
    outline: 3px solid #009fe3 !important;
}
.mgr-row-with-border{
    outline: 3px solid  #163340;
}

.mgr-row {
    border-radius: 12px;
    padding: 6px;
    margin-bottom: 10px;
}

.row-shadow {
    -webkit-box-shadow: 2px 2px 30px -15px rgba(66, 68, 90, 1);
    -moz-box-shadow: 2px 2px 30px -15px rgba(66, 68, 90, 1);
    box-shadow: 2px 2px 30px -15px rgba(66, 68, 90, 1);
}

.show-details {
    padding: 5px !important;
}


.grid-row-header-mobile {
    text-align: center;
    font-size: 25px;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-bottom: 10px;
    //background-color: #2a3042 !important;
    background-color: #181854 !important;
    color: #ffffff !important;
    border-radius: 0.75rem; //1rem;
}

.grid-row-mobile-even {
    background-color: #e0e0e0 !important;

    .accordion-button {
        background-color: #e0e0e0 !important;
    }
}

.grid-row-mobile-odd {
    background-color: #ffffff !important;

    .accordion-button {
        background-color: #ffffff !important;
    }
}

.grid-row-wide {
    text-align: center;
    vertical-align: middle;
    padding-top: 20px;
    height: 60px;
}


.accordion-panel {
    border-top: 1px solid #dee2e6;
    background-color: #efefef;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: 5px;
    padding: 8px;
}





.table-con span {
    color: #8E9ABB;
    text-align: left;
    font-family: DM Sans;
    //font-size: 14.278px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    word-break: break-all;
}

.table-con {
    margin-bottom: 4px;
}

.table-con-inline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 8px;
}

.table-con>label,
.table-con>p,
.table-con>p>span {
    margin-bottom: 0px;
    font-family: DM Sans;

}

.table-con>p,
.table-con>p>span {
    color: #8E9ABB;
}
.table-con>label{
    color: #586887;
}



.table-con>p{
    min-width: 80px;
    text-align: right;
}

.table-con-grid>thead>tr>th {
    color: #8E9ABB;
    font-family: DM Sans;
    font-weight: 500;
}

.table-con-grid>tbody>tr .table-con-grid>thead>tr {
    height: 28px !important;
}

.table-con-grid>tbody>tr>td,
.table-con-grid>thead>tr>td {
    color: #8E9ABB;
    font-family: DM Sans;
    font-size: 14px;
}

.table-con>p {
    font-weight: 400;
}

.table-con>label {
    font-weight: 500;
}


.summary-row-first>.table-con>p {
    border-top: 1px dashed #8E9ABB;
}

.summary-row-first>.table-con>p,
.summary-row-first>.table-con>label {
    padding-top: 5px;
    margin-top: 5px;
    font-weight: 700;
}

.summary-row-first>.table-con>label {
    font-weight: 700;
    color: #3A6E84;
}

.summary-row-first>.table-con>p, .summary-row-first>.table-con>p>span,
.summary-row>.table-con>p, .summary-row>.table-con>p>span {
    font-weight: 700;   
}

.summary-row>.table-con>label {
    font-weight: 700;
    color: #3A6E84;
}

.summary-row-first .money-positive,
.summary-row .money-positive {
    color: #34C759 !important;
}



.flex-buttons {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-content: space-around;
    gap: 5px 10px;

}

.flex-buttons>div>h2 {
    margin-top: 5px;
}


.icon-button-window {
    width: 20px;
    vertical-align: middle;
    text-align: center;
}

@media (max-width: 768px) {
    .w-100-mobile {
        width: 100%;
    }
}

.fa-xl {
    vertical-align: middle;
}



.border-none {
    border-collapse: collapse;
    border: none;
}

.border-none td {
    border: 1px solid #dee2e6;
}

.border-none tr:first-child td {
    border-top: none;
}

.border-none tr:last-child td {
    border-bottom: none;
}

.border-none tr td:first-child {
    border-left: none;
}

.border-none tr td:last-child {
    border-right: none;
}

.money-r-spacer {
    padding-right: 6px;
}

.grid-extender {
    margin-left: -10px;
    margin-right: -10px;
}

.button-get-more-data {
    margin-bottom: 10px;
}