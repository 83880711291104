@media (max-width: 767px) {
	.row.items-center.h-100 {
	    height: auto !important;
	}
	 
	.desktop-hide{
		display: block;
	}
	.mobile-hide{
		display: none;
	}
	#addInvoiceModal .all-fields {
		grid-template-columns: repeat(1,1fr);
	}
	#addInvoiceModal .all-fields .full-width {
		grid-column: 1/1;
	}
	.all-fields {
		grid-template-columns: repeat(1,1fr);
		gap: 20px;
	}
	.all-fields .input-fields.full-width {
		grid-column: 1/1;
	}
	.mobile-language .language-icon , .user-icon .user-image {
	    width: 25px;
	}

	aside {
		display: none;
	}
	.overlay {
		position: relative;
	}
	.col-md-1 , .col-md-2 , .col-md-3 , .col-md-4 , .col-md-5 , .col-md-6 , .col-md-7 , .col-md-8 , .col-md-9 , .col-md-10 , .col-md-11 , .col-md-12 {
		width: 100%;
	}
	.overlay:after {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-color: #000;
		opacity: 0.38;
	}
	.menu-open {
		background: transparent;
		outline: none;
		border: none;
		box-shadow: none;
	}
	.header-mob{
		background-color: #fff;
	}
	.mobile-nav {
		display: block;
		position: fixed;
		top: 0px;
		right: 0px;
		width: 310px;
		height: 100vh;
		background: var(--dark);
		overflow-x: hidden;
		overflow-y: auto;
		z-index: 99999;
		pointer-events: none;
		transform: translateX(100%);
		opacity: 0;
		transition: 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
	}
	.close {
		position: absolute;
		top: 12px;
		right: 20px;
		font-size: 10px;
		font-weight: bold;
		color: white;
		cursor: pointer;
		background: none;
		border: none;
	}
	.close img{
		width: 15px;
	}
	.header {
		display: none;
	}
	.header-mob {
		display: block !important;
	}
	.id-date {
		gap: 10px;
		flex-direction: column;
		align-items: flex-start;
	}
	.profit-brdr span {
		font-size: 15px;	
	}
	.info-det h3 {
		font-size: 13px;
	}
	.flex-info p {
		font-size: 12px;
	}
	.flex-info {
		gap: 5px;
	}
	.l-part {
		align-items: flex-end;
		flex-direction: column;
		gap: 10px;
	}
	.arrow img {
		width: 30px;
	}
	.f-img img {
		width: 90%;
	}
	.f-part {
		gap: 10px;
	}
	.main-h2 h2 {
		font-size: 24px;
		line-height: 33.499px;
	}
	.con-box span,
	.info-box span {
		font-size: 13.4px;
		line-height: 20.1px;
	}
	.con-box h3 > span,
	.con-box h3,
	.info-box h3 {
		margin-top: 4px;
		font-size: 24px;
		line-height: 28px;
	}

	 
	.footer {
		background: #163340;
		padding: 15px;
	}
	
	.logo img {
		max-width: 200px !important;
	}
	 
	.m-header {
		display: flex;
		gap: 45px;
		align-items: center;
	}
	.mob-nav {
		display: flex;
		align-items: center;		
		justify-content: flex-end;
		gap: 25px;

	}
	.settlement {
		padding: 50px 0px;
		margin-bottom: 50px;
	}
	.mobile-nav.active {
		transform: translateX(0px);
		opacity: 1;
		pointer-events: unset;
		background: #163340;
	}
	.warning-btn {
		text-align: left;
		margin-top: 10px;
	}
	.warning-txt p {
		font-size: 12px;
	}
	.res-btm {
	    margin-bottom: 10px;
	}
	.form-title h2 {
		margin-top: 10px;
		font-size: 26px;
		line-height: 28.506px;
	}
	.img-100 img{
		border-radius: 25px 25px 0px 0px;
	}
	 
	.only-mob {
		display: block !important;
	}
	.f-part {
		width: 75%;
	}
	section.header-mob {
		position: sticky;
		top: 0;
		z-index: 20;
	}
	.overlay .header-mob {
		z-index: -1;
	}
	.overlay .main-content {
		position: relative;
		z-index: -2;
	}
	 
	 
	.btn-links-left a, .btn-links a {
		font-size: 13px;
	}
	.btn-links-left, .btn-links {
		justify-content: flex-start !important;
	}
	.logo {
		padding: 5px 0px;
		text-align: left;
	}
	#bar-chart-desktop {
		display: none !important;
	}
	#bar-chart-mobile{
		display: block !important;
	}
	 
	.content-b-p {
		padding: 20px 0px;
	}
	.mobile-dropdown-box{
		display: none;
	}
	.mobile-language {
		position: relative;
	}
	.mobile-user {
		position: relative;
	}
	.mobile-dropdown-box {
		position: absolute;
		right: 0;
	}
	.mobile-dropdown-box ul {
		padding: 15px;
		margin: 0;
		list-style: none;
		background-color: #fff;
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
	}
	.mobile-dropdown-box ul li a {
		display: flex;
		align-items: center;
		column-gap: 16px;
		color: #163340;
		font-family: DM Sans;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		text-decoration: none;
	}
	.user-language-active {
		display: block;
	}
	 
	.side-btm {
	    margin: 15px;
	}
	.danger-btn {
		border-left: none;
		border-top: 1px solid #E2E9FC;
		padding-top: 30px;
		text-align: left;
	}
 
	 
	.contract-type-btns {
		grid-template-columns: repeat(1,1fr);
	}
   
	.contract-type-btns button,
	.contract-type-btns a {
		justify-content: flex-start;
	}

 
	#thankyouModal .modal-body {
		padding: 25px;
	}
	.list-document {
		grid-template-columns: repeat(1,1fr);
	}
	.list-document-head {
		gap: 18px;
		flex-direction: column;
		align-items: start;
	}
	.list-document-head button {
	    font-size: 11px;
	}
	.list-document .documents .front h3 {
	    font-size: 14px;
	}
	.head-with-badge .badge {
	    font-size: 11px;
	    padding: 4px 8px;
	}
 
	.list-document .documents .front p {
	    font-size: 12px;
	}
	.list-document .documents{
		padding: 20px 15px;
		margin-bottom: 20px;
	}
	.form-fields .flex-input {
		flex-wrap: wrap;
	}
	.uploadFile {
		padding: 24px;
	}
	.uploadFile .filename {
		font-size: 14px;
	}
	.uploadFile img {
		width: 20px;
		height: 20px;
	}
	.form-buttons {
		flex-wrap: wrap;
		gap: 20px;
		justify-content: center;
	}
	.form-buttons .blue-btn , .form-buttons .close {
		font-size: 16px;
	}
	#poleCardModal .modal-body, #residenceCardModal .modal-body {
		padding: 40px 20px;
	}
	.flex-chart {
		margin-top: 20px;
		flex-wrap: wrap;
		justify-content: center;
	}
	.invoice-table-nav .nav-tabs {
		flex-wrap: nowrap;
		overflow: hidden;
		overflow-x: scroll;
		padding-bottom: 10px;
	}
	.list-invoice-head {
		flex-wrap: wrap;
		gap: 20px;
	}
	.working-list .saving-img {
		top: -95px;
		right: -9px;
		width: 110px;
		height: 110px;
	}

	.working-list ul{
		margin-top: 38px;
	}

	.aff-link-first-panel{
		height: auto;
	}
	.aff-link-first-panel-shitf{
		margin-top: 0px;
	}

	.working-list{
		height: auto;
	}
	 
 
	.table-con span:first-child {
	    width: 49%;
	}
	.hidden-con{
		border-radius: 0px;
	}
	.sidemenu-styles{
		margin: 0px 18px;
	}
	ul.sidemenu-styles li{
		padding: 8px 10px;
	}
	.mt-40{
		margin-top: 20px;
	}

	.wrapper-flex {
		gap: 30px;
		flex-direction: column;
	}

	.inline-buttons {
		padding-top: 5px !important;
		padding-bottom: 10px !important;		
	}


	.unauth-panel{
		padding-left: 20px;
		padding-top: 5px;
		padding-right: 20px;
		padding-bottom: 20px;
	 }

	 .bg-brdr {			
		padding: 10px 12px 0px 12px;
	}

 .row-item-header {
	font-size: 18px;
} 


.btn-agr-type{
	display: flex;
	align-items: center;
	gap: 8px;
	justify-content: center;		
}

.contract-type-btns button.with-details,
.contract-type-btns a.with-details {
	height: 150px;	
}
 

.btn-agr-type  {
	text-align: start !important;
}
 

.button-grid-to-left{
	margin-left: 0px;
}
}
 