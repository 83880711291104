@media screen {
    @media (max-width: 900px) {
        .reg-app-item-grid {
            grid-template-columns: 100px 100px 100px;
        }
    }

    @media (min-width: 900px) {

        .deal-add > .reg-app-item-grid {
            justify-content: flex-start !important;
        }

        .reg-app-item-grid {
            grid-template-columns: 100px 100px 100px 100px 100px;

            .reg-app-item-grid {
                justify-content: center !important;
            }

           


            .app-emphasized {
                width: 290px !important;
            }
        }
    }
}

.reg-app-item-grid {
    display: flex;
    flex-wrap: wrap;
    padding: 0px;
    column-gap: 4px;
    row-gap: 5px;
    justify-content: center;
}

.reg-app-item {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: 0px;
}






.app-emphasized {
    width: calc(100% - 50px) !important;
    background-color: #009FE3;
    border-radius: 45px !important;
    height: 90px !important;
    padding-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
    justify-content: flex-start !important;
}

.app-emphasized.inactive {
    border: 1px solid #efefef !important;
    background-color: #efefef !important;
}

.app-emphasized>.app-name {
    display: block !important;
    font-size: px;
    padding-left: 10px;
}


.app-name.active {
    color: white;
}

.app-name.inactive {
    color: #009FE3;
}


.reg-app-item-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    background-color: #009FE3;
    border-radius: 50%;
}

.reg-app-item-wrapper.active {
    background-color: #009FE3;
}

.reg-app-item-wrapper.inactive {
    background-color: #ffffff;
}



.reg-app-item.active {
    background-color: #ffffff;
}

.reg-app-item.inactive {
    background-color: efefef !important;
    opacity: 50%;
}


.app-emphasized > .reg-app-item.inactive {
    background-color: white !important;    
}

.register-courier-or-taxi-options{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.reg-taxi-or-courier-item-wrapper {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;    
    text-align: center;
    padding: 10px 0;
    border-radius: 8px;    
    margin: 0 0 20px 0;    
    width: 100%;

    img {
        height: 89px;
    }
    span{
        font-weight: 500;
    }
}
.reg-taxi-or-courier-item-wrapper.active {    
    outline: 6px solid #009FE3;
    
    span{
        font-weight: 600;
    }
}
.reg-taxi-or-courier-item-wrapper.inactive {
    outline: 6px solid #d4d4d4;
    span{
        color: #212529bf !important;
    }
}

.reg-taxi-or-courier-item.inactive {
    opacity: 30%;
   
}

.reg-taxi-or-courier-item.inactive {
    
    opacity: 50%;
   
}
