.hr-sect {
    display: flex;
    flex-basis: 100%;
    align-items: center;
    color: rgb(126, 126, 126);
    margin: 5px -10px 5px -10px;

    &:before, &:after {
        content: "";
        flex-grow: 1;
        background: rgb(126, 126, 126);
        height: 1px !important;
        font-size: 0;
        line-height: 0;
        margin: 0 10px;
    }
}