.basic-light {
  //background-color: #e8f4fc !important;
  border:none !important;
}

.basic-warning {
	border: 2px solid #FCA004;
	border-radius: 8px;
	background: #FFFBED;
}

.helper-header{
  min-height: 40px;
  margin: 0px;
  padding: 0px 12px 0px 12px /*0px 12px 8px 12px*/;
}

.helper-text-extender{
  margin-left: -12px;
  margin-right: -12px;
}