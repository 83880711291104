.alert-ligth-green {
    background-color: #caf5c7 !important;
    border-color: #caf5c7 !important;
    color: #5a5a5a !important;
  }
  
  .alert-standard {
  background-color: #181854 !important;
  border-color: #181854 !important;
  color: white !important;
  }

  .shake {
    animation: shake-animation 20s ease infinite;
    transform-origin: 50% 50%;
  }
 
  @keyframes shake-animation {
     0% { transform:translate(0,0) }
    1.78571% { transform:translate(5px,0) }
    3.57143% { transform:translate(0,0) }
    5.35714% { transform:translate(5px,0) }
    7.14286% { transform:translate(0,0) }
    8.92857% { transform:translate(5px,0) }
    10.71429% { transform:translate(0,0) }
    100% { transform:translate(0,0) }
  }